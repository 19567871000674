<template>
    <div class="app-content app-container">
        <!-- 搜索 -->
        <div style="margin-bottom: 20px">
            <el-form
                :inline="true"
                :model="formInline"
                class="demo-form-inline"
                ref="formInline"
                @submit.native.prevent
            >
                <el-form-item label="期刊" prop="period">
                    <el-input
                        style="width: 300px"
                        v-model="formInline.period"
                        placeholder="期刊"
                        clearable
                        @keyup.enter.native="onSubmit"
                    ></el-input>
                </el-form-item>
                <el-form-item label="问卷ID" prop="sourceId">
                    <el-input
                        style="width: 300px"
                        v-model="formInline.sourceId"
                        placeholder="问卷ID"
                        clearable
                        @keyup.enter.native="onSubmit"
                    ></el-input>
                </el-form-item>
                <el-form-item label="推送批号" prop="batchNumber">
                    <el-input
                        style="width: 300px"
                        v-model="formInline.batchNumber"
                        placeholder="推送批号"
                        clearable
                        @keyup.enter.native="onSubmit"
                    ></el-input>
                </el-form-item>
                <el-form-item label="推送编号" prop="dataNo">
                    <el-input
                        style="width: 300px"
                        v-model="formInline.dataNo"
                        placeholder="推送编号"
                        clearable
                        @keyup.enter.native="onSubmit"
                    ></el-input>
                </el-form-item>
                <el-form-item label="推送内容搜索" prop="downloadMsg">
                    <el-input
                        style="width: 300px"
                        v-model="formInline.downloadMsg"
                        placeholder="推送内容搜索"
                        clearable
                        @keyup.enter.native="onSubmit"
                    ></el-input>
                </el-form-item>

                <el-form-item>
                    <el-button type="primary" @click="onSubmit">搜索</el-button>
                    <el-button @click="resetForm('formInline')">重置</el-button>
                </el-form-item>
            </el-form>
        </div>
        <!-- 表格 -->
        <div>
            <el-table
                ref="multipleTable"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                @selection-change="handleSelectionChange"
            >
                <el-table-column type="selection" width="55"></el-table-column>
                <el-table-column prop="id" label="#"> </el-table-column>
                <el-table-column prop="sourceId" label="问卷ID">
                </el-table-column>
                <el-table-column prop="type" label="推送类型">
                </el-table-column>
                <el-table-column prop="period" label="期刊"> </el-table-column>
                <el-table-column prop="batchNumber" label="推送批号">
                </el-table-column>
                <el-table-column prop="dataNo" label="推送编号">
                </el-table-column>
                <el-table-column prop="release" label="总数-索引">
                    <template slot-scope="scope"
                        >{{ scope.row.pageCount }} -
                        {{ scope.row.pageStart }}</template
                    >
                </el-table-column>
                <el-table-column prop="release" label="推送结果"
                    ><template slot-scope="scope"
                        >{{ scope.row.downloadTime
                        }}{{ scope.row.downloadMsg }}</template
                    >
                </el-table-column>
                <el-table-column prop="release" label="上传结果"
                    ><template slot-scope="scope"
                        >{{ scope.row.uploadTime
                        }}{{ scope.row.uploadMsg }}</template
                    >
                </el-table-column>
                <el-table-column label="操作" fixed="right" width="150">
                    <template slot-scope="scope">
                        <el-button type="text" @click="xmlClick(scope.row)">
                            XML数据
                        </el-button>
                        <!-- <a :href="`/file/log/xml/${scope.row.id}`" class="btn btn-primary btn-xs" target="_blank" title="xml数据">
            xml数据
              <i class="fa fa-database" aria-hidden="true"></i>
            </a> -->

                        <el-button type="text" @click="editClick(scope.row)"
                            >预览</el-button
                        >
                    </template>
                </el-table-column>
                <!-- <el-table-column
          prop="time"
          label="填写时间"
           
        >
          <template slot-scope="scope">{{ scope.row.date }}</template>
        </el-table-column> -->
            </el-table>
            <!-- 分页 -->
            <div>
                <Pagination
                    v-bind:child-msg="pageparm"
                    @callFather="callFather"
                ></Pagination>
            </div>
        </div>
        <!-- 弹窗 -->
    </div>
</template>
<script>
import Pagination from '@/components/Pagination'
import { mofcomListApi } from '@/api/survey.js'
export default {
    // 注册组件
    components: {
        Pagination,
    },
    data() {
        return {
            formInline: {},
            tableData: [],
            multipleSelection: [],
            pageparm: {
                currentPage: 1,
                pageSize: 10,
                total: 20,
            },
        }
    },
    created() {
        if (sessionStorage.getItem('formInline')) {
            this.formInline = JSON.parse(sessionStorage.getItem('formInline'))
        }
        this.getMofcomListFun()
    },
    methods: {
        // 获取商务部推送日志
        async getMofcomListFun(currentPage, pageSize) {
            var start = currentPage ? currentPage * pageSize - pageSize : 0
            var params = {
                start: start,
                length: pageSize ? pageSize : 10,
                draw: 1,
                orderStr: 'id desc',
            }
            var p = Object.assign(params, this.formInline)
            var res = await mofcomListApi(p)
            this.tableData = res.data
            this.pageparm.total = res.recordsTotal
            console.log('商务部推送日志', this.tableData)
        },
        onSubmit() {
            sessionStorage.setItem(
                'formInline',
                JSON.stringify(this.formInline)
            )
            this.getMofcomListFun()
        },
        resetForm(formInline) {
            sessionStorage.removeItem('formInline')
            this.formInline = {
                name: '',
            }
            this.$refs[formInline].resetFields()
            this.getMofcomListFun()
        },
        handleSelectionChange(val) {
            this.multipleSelection = val
        },
        // 分页插件事件
        callFather(parm) {
            // this.formInline.page = parm.currentPage
            // this.formInline.limit = parm.pageSize
            this.getMofcomListFun(parm.currentPage, parm.pageSize)
        },
        // 编辑模板
        editClick(row) {
            console.log('row', row)
            this.$store.commit('xml/SET_CONTENT', row)
            this.$router.push({
                path: '/businessPushLog/preview',
            })
        },
        // xml数据
        xmlClick(item) {
            this.$store.commit('xml/SET_CONTENT', item)
            this.$router.push({
                path: '/businessPushLog/xml',
            })
        },
    },
}
</script>
<style scoped>
.app-content {
    background-color: #fff;
    padding: 20px;
}
/* ::v-deep .el-form--inline .el-form-item__content{
  width: 100%;
} */
</style>
