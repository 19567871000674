import request from '@/utils/request.js'
import download from '@/utils/request.js'

// 问卷列表
export function surveyListApi(data) {
  return request({
    url: '/doftec/admin-survey/list',
    method: 'post',
    data: data
  })
}

// 新增问卷
export function surveyAddApi(data) {
  return request({
    url: '/doftec/admin-survey/create',
    method: 'post',
    data: data
  })
}
// 修改问卷
export function surveyUpdataApi(data) {
  return request({
    url: '/doftec/admin-survey/update.u',
    method: 'post',
    data: data
  })
}


// 根据问卷id获取详情
export function surveyDetailApi(query) {
  return request({
    url: '/doftec/admin-survey/get',
    method: 'get',
    params: query
  })
}

// 推送/锁定问卷状态 menuType：[delete：删除 status：修改状态[menuValue:0 否 ，1 是] push：推送]
export function surveyActionApi(data) {
  return request({
    url: '/doftec/admin-survey/action',
    method: 'post',
    data: data
  })
}

// 下载分析结果
export function surveyDownloadApi(params,filename) {
  return download({
    url: '/doftec/admin-survey/download',
    params:params,
    filename:filename
  })
}

// 问题答案列表
export function answerListApi(data) {
  return request({
    url: '/doftec/admin-survey/answer/list',
    method: 'post',
    data: data
  })
}
 
// 删除问题答案 根据id  menuType 操作状态：delete 删除 push 推送 download 下载 ids:[]
export function answerDeleteApi(data) {
  return request({
    url: '/doftec/admin-survey/answer/action',
    method: 'post',
    data: data
  })
}

// 修改问题答案  根据 id 修改
export function answerUpdateApi(data) {
  return request({
    url: '/doftec/admin-survey/update.u',
    method: 'post',
    data: data
  })
}

// 商务部推送日志列表
export function mofcomListApi(data) {
  return request({
    url: '/doftec/admin-survey/mofcom/list',
    method: 'post',
    data: data
  })
}

// 用户平台信息列表
export function userAgentListApi(data) {
  return request({
    url: '/doftec/admin-survey/user/agent/list',
    method: 'post',
    data: data
  })
}

// 百度OCR识别接口
export function baiduOcr(data,params) {
  return request({
    url: '/api/baiduOcr/business_license',
    method: 'post',
    data: data,
    params: params
  })
}


